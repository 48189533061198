.homepage {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(55,55,55,1) 100%);
    color: WHITE;

    .row,
    .container {
        height: 100%;
    }

    .col-xs-12 {
        display: flex;
        height: 100%;
        flex-flow: row wrap;
        justify-content: center;
        align-content: center;

        h1,
        p {
            width: 100%;
            margin: 0 20 20;
            text-align: center;
            @include fontSize(20);
        }
        a {
            color: white;
        }
        img {
            width: 100%;
            max-width: 400px;
            margin: 0 20 0;
        }
    }
}